// NoticeList.tsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import {GetQuestionsListApi }from './Data/QuestionsApi';
import { toast } from 'react-toastify'; // 에러 알림을 위한 라이브러리
import { dateFormat } from "../../../common/function/date";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import SearchBar from 'components/pages/Retensions/shared/SearchBar';
import CustomDropdown from 'components/pages/Retensions/shared/CustomDropdown';
import CompanyReview from './CompanyReview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background: white;
  min-height: 100vh;
`;

const LeftPanel = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RightPanel = styled.div`
  flex-grow: 1;
  background: #F8F8F8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  background: #F8F8F8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: seperate;
  margin-top: 10px;
  background: #F8F8F8;
  border-spacing: 0 10px;
`;

const TableHead = styled.thead`
  background: #F8F8F8;
`;
const TableRow = styled.tr`
  background: #F8F8F8;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #E8F0FE;
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  &:hover td {
    background: #E8F0FE;
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  font-size: 15px;
  text-align: center;
  background: #F8F8F8;
  color: #007bff
  
  
`;

const TableCell = styled.td`
    padding: 10px;
    background: #fff;
    text-align: center;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s ease-in-out;

    &:first-child {
        border-left: 1px solid #eee;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &:last-child {
        border-right: 1px solid #eee;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
  
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`

  border: 1px solid #007bff;
  padding: 5px 10px;
  margin: 0 3px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: #0056b3;
    color: #fff;
  }
`;
const Title = styled.h2`
    font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;
const SubTitle = styled.h2`
margin-top : -5px;
    font-size: 20px;
  font-weight: 700;
  color: #2282e9;
`;

const Wrapper = styled.div`
  flex: 4;
  margin-inline: 20px;
`;
const ListContainer = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ListContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`;

const BusinessTag = styled.span`
  background: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;

`;

const GameName = styled.span`
  flex-grow: 1;
  margin: 0 10px;
  font-size: 16px;
  color: #0056b3;
  
`;

const ReviewButton = styled.button`
  background: #e9ecef;
  color: #6c757d;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: #dee2e6;
  }
`;

const handleChange = (option: string) => {
    console.log('선택된 옵션:', option);
};

const ITEMS_PER_PAGE = 5;
const TABLE_ITEMS_PER_PAGE = 10;
const items = Array(23).fill("CPR-Games");
const rejectedCompanies = Array(32).fill("CPR-Games"); // 더미 데이터 수정
const gameData = [
    { date: "2025.01.01", name: "CPR - Games", money: 0, tests: 1, admin: "임서원", status: "승인" },
    { date: "2025.01.02", name: "XYZ - Games", money: 100, tests: 2, admin: "김철수", status: "반려" },
    { date: "2025.01.03", name: "ABC - Games", money: 50, tests: 3, admin: "이영희", status: "심사" }
].concat(Array(48).fill({ date: "2025.01.04", name: "Test Games", money: 0, tests: 0, admin: "관리자", status: "승인" }));

const CompanyManagement: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [rejectedCurrentPage, setRejectedCurrentPage] = useState(1);
    const [tableCurrentPage, setTableCurrentPage] = useState(1);
    const [showReview, setShowReview] = useState(false);
    
    // URL 파라미터에서 review 상태를 가져옴
    const isReviewMode = searchParams.get('mode') === 'review';
    const businessNumber = searchParams.get('businessNumber');

    // 심사하기 버튼 클릭 핸들러
    const handleReviewClick = (businessNumber: string) => {
        setSearchParams({ mode: 'review', businessNumber });
    };

    // 심사 화면 닫기 핸들러
    const handleReviewClose = () => {
        setSearchParams({});
    };

    const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);
    const rejectedTotalPages = Math.ceil(rejectedCompanies.length / ITEMS_PER_PAGE);
    
    const pageGroupSize = 5;

    // 게임사 심사 진행 페이지네이션
    const currentGroup = Math.floor((currentPage - 1) / pageGroupSize);
    const startPage = currentGroup * pageGroupSize + 1;
    const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

    // 반려된 게임사 페이지네이션
    const rejectedCurrentGroup = Math.floor((rejectedCurrentPage - 1) / pageGroupSize);
    const rejectedStartPage = rejectedCurrentGroup * pageGroupSize + 1;
    const rejectedEndPage = Math.min(rejectedStartPage + pageGroupSize - 1, rejectedTotalPages);

    // 현재 페이지 아이템들
    const currentItems = items.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    // 반려된 게임사 현재 페이지 아이템들
    const currentRejectedItems = rejectedCompanies.slice(
        (rejectedCurrentPage - 1) * ITEMS_PER_PAGE,
        rejectedCurrentPage * ITEMS_PER_PAGE
    );

    // 테이블 페이지네이션 계산 (10개씩)
    const tableTotalPages = Math.ceil(gameData.length / TABLE_ITEMS_PER_PAGE);
    const tableCurrentGroup = Math.floor((tableCurrentPage - 1) / pageGroupSize);
    const tableStartPage = tableCurrentGroup * pageGroupSize + 1;
    const tableEndPage = Math.min(tableStartPage + pageGroupSize - 1, tableTotalPages);

    // 테이블 현재 페이지 데이터 (10개씩)
    const currentTableData = gameData.slice(
        (tableCurrentPage - 1) * TABLE_ITEMS_PER_PAGE,
        tableCurrentPage * TABLE_ITEMS_PER_PAGE
    );

    return (
        <Wrapper>
            <Title>게임사 관리</Title>
            {isReviewMode ? (
                <CompanyReview 
                    onClose={handleReviewClose}
                    businessNumber={businessNumber || ''} 
                />
            ) : (
                <Container>
                    <LeftPanel>
                        <Section>
                            <ListContainer>
                                <SubTitle>게임사 심사 진행</SubTitle>
                                <ListContent>
                                    {currentItems.map((game, index) => (
                                        <ListItem key={index}>
                                            <BusinessTag>사업자</BusinessTag>
                                            <GameName>{game}</GameName>
                                            <ReviewButton 
                                                onClick={() => handleReviewClick('726-88-02485')}
                                            >
                                                심사하기
                                            </ReviewButton>
                                        </ListItem>
                                    ))}
                                </ListContent>
                                <Pagination>
                                    <PageButton 
                                        onClick={() => setCurrentPage(Math.max(1, startPage - pageGroupSize))}
                                        disabled={startPage === 1}
                                    >
                                        &laquo;
                                    </PageButton>
                                    {Array.from({ length: endPage - startPage + 1 }, (_, i) => {
                                        const pageNum = startPage + i;
                                        return (
                                            <PageButton
                                                key={pageNum}
                                                onClick={() => setCurrentPage(pageNum)}
                                                style={currentPage === pageNum ? { background: '#007bff', color: '#fff' } : {}}
                                            >
                                                {pageNum}
                                            </PageButton>
                                        );
                                    })}
                                    <PageButton 
                                        onClick={() => setCurrentPage(Math.min(totalPages, endPage + 1))}
                                        disabled={endPage === totalPages}
                                    >
                                        &raquo;
                                    </PageButton>
                                </Pagination>
                            </ListContainer>
                        </Section>
                        <Section>
                            <ListContainer>
                                <SubTitle>반려된 게임사</SubTitle>
                                <ListContent>
                                    {currentRejectedItems.map((game, index) => (
                                        <ListItem key={index}>
                                            <BusinessTag>사업자</BusinessTag>
                                            <GameName>{game}</GameName>
                                            <ReviewButton onClick={() => handleReviewClick('726-88-02485')}
                                            >상세보기</ReviewButton>
                                        </ListItem>
                                    ))}
                                </ListContent>
                                <Pagination>
                                    <PageButton 
                                        onClick={() => setRejectedCurrentPage(Math.max(1, rejectedStartPage - pageGroupSize))}
                                        disabled={rejectedStartPage === 1}
                                    >
                                        &laquo;
                                    </PageButton>
                                    {Array.from({ length: rejectedEndPage - rejectedStartPage + 1 }, (_, i) => {
                                        const pageNum = rejectedStartPage + i;
                                        return (
                                            <PageButton
                                                key={pageNum}
                                                onClick={() => setRejectedCurrentPage(pageNum)}
                                                style={rejectedCurrentPage === pageNum ? { background: '#007bff', color: '#fff' } : {}}
                                            >
                                                {pageNum}
                                            </PageButton>
                                        );
                                    })}
                                    <PageButton 
                                        onClick={() => setRejectedCurrentPage(Math.min(rejectedTotalPages, rejectedEndPage + 1))}
                                        disabled={rejectedEndPage === rejectedTotalPages}
                                    >
                                        &raquo;
                                    </PageButton>
                                </Pagination>
                            </ListContainer>
                        </Section>
                    </LeftPanel>

                    {/* 우측 패널 (검색창 + 테이블) */}
                    <RightPanel>
                        <SearchBar
                            // onSearch={handleSearch}
                            placeholder="게임사 이름을 검색하세요."
                        />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>등록 일시</TableHeader>
                                    <TableHeader>게임사명</TableHeader>
                                    <TableHeader>보유 플리머니</TableHeader>
                                    <TableHeader>진행 테스트 수</TableHeader>
                                    <TableHeader>관리자</TableHeader>
                                    <TableHeader>
                                        <CustomDropdown
                                            label="상태" 
                                            options={['승인', '반려', '심사']}
                                            onChange={handleChange}
                                            labelColor="#2282e9;"
                                        />
                                    </TableHeader>
                                </TableRow>
                            </TableHead>
                            {currentTableData.map((game, index) => (
                                <TableRow 
                                    key={index} 
                                    onClick={() => handleReviewClick('726-88-02485')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{game.date}</TableCell>
                                    <TableCell>{game.name}</TableCell>
                                    <TableCell>{game.money}</TableCell>
                                    <TableCell>{game.tests}</TableCell>
                                    <TableCell>{game.admin}</TableCell>
                                    <TableCell>{game.status}</TableCell>
                                </TableRow>
                            ))}
                        </Table>

                        {/* 페이지네이션 */}
                        <Pagination>
                            <PageButton 
                                onClick={() => setTableCurrentPage(Math.max(1, tableStartPage - pageGroupSize))}
                                disabled={tableStartPage === 1}
                            >
                                &laquo;
                            </PageButton>
                            {Array.from({ length: tableEndPage - tableStartPage + 1 }, (_, i) => {
                                const pageNum = tableStartPage + i;
                                return (
                                    <PageButton
                                        key={pageNum}
                                        onClick={() => setTableCurrentPage(pageNum)}
                                        style={tableCurrentPage === pageNum ? { background: '#007bff', color: '#fff' } : {}}
                                    >
                                        {pageNum}
                                    </PageButton>
                                );
                            })}
                            <PageButton 
                                onClick={() => setTableCurrentPage(Math.min(tableTotalPages, tableEndPage + 1))}
                                disabled={tableEndPage === tableTotalPages}
                            >
                                &raquo;
                            </PageButton>
                        </Pagination>
                    </RightPanel>
                </Container>
            )}
        </Wrapper>
    );
};

export default CompanyManagement;