// NoticeList.tsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {GetQuestionsListApi }from './Data/QuestionsApi';
import { toast } from 'react-toastify'; // 에러 알림을 위한 라이브러리
import {dateFormat} from "../../../common/function/date";
import { useNavigate } from "react-router-dom";
import { Outlet } from 'react-router-dom';
interface Notice {
  id: number;
  title: string;
  companyName: string;
  createdAt: Date;
}

interface Pagination {
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  first: boolean;
  last: boolean;
}

// 스타일 컴포넌트 정의
const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
`;

const Tab = styled.button<{ isActive: boolean }>`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${props => props.isActive ? '#007bff' : '#666'};
  border-bottom: ${props => props.isActive ? '2px solid #007bff' : 'none'};
`;

const NoticeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const NoticeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:hover {  // 호버 효과 추가
    background-color: #e9ecef;
  }
`;

const NoticeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Company = styled.span`
  color: #007bff;
  font-size: 14px;
`;

const Text = styled.p`
  color: #333;
  font-size: 16px;
  margin: 0;
`;

const Date = styled.span`
  color: #999;
  font-size: 14px;
`;
const Wrapper = styled.div`
  flex: 4;
  margin-inline: 20px;
`;

// 페이지네이션 스타일 추가
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 10px;
`;

const PageButton = styled.button<{ isActive?: boolean }>`
  padding: 8px 12px;
  border: 1px solid ${props => props.isActive ? '#007bff' : '#dee2e6'};
  background-color: ${props => props.isActive ? '#007bff' : 'white'};
  color: ${props => props.isActive ? 'white' : '#333'};
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.isActive ? '#007bff' : '#f8f9fa'};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;


// 컴포넌트
const Questions: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'PENDING' | 'COMPLETE'>('PENDING');

  const handlePageChange = (newPage: number) => {
    setPageable(prev => ({
      ...prev,
      pageNumber: newPage
    }));

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  };

  const [notices, setNotices] = useState<Notice[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // 페이지 번호 배열 생성
  const [pageable, setPageable] = useState<Pagination>({
    pageNumber: 1,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    first: true,
    last: true
  });

  const fetchNoticesData = async (currentPage: number, status: string) => {
    // setLoading(true);
    // setError(null);
    const pageSize = pageable.pageSize;
    try {
      const response = await GetQuestionsListApi(currentPage, pageSize, status);

      if (response?.data) {
        if (response.data.page) {
          setNotices(response.data.page.content);
          setPageable({
            pageNumber: response.data.page.pageable.pageNumber + 1,
            pageSize: response.data.page.pageable.pageSize,
            totalElements: response.data.page.pageable.totalElements,
            totalPages: response.data.page.pageable.totalPages,
            first: response.data.page.pageable.first,
            last: response.data.page.pageable.last
          });
        }
      }
    } catch (error) {
      setError('데이터를 불러오는데 실패했습니다.');
      toast.error('데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNoticesData(pageable.pageNumber, activeTab);
  }, [pageable.pageNumber, activeTab]);

  // 현재 페이지 그룹을 계산하는 함수
  const getCurrentPageGroup = () => {
    return Math.floor((pageable.pageNumber - 1) / 5);
  };

  // 페이지 그룹의 시작과 끝 번호를 계산
  const getPageRange = () => {
    const currentGroup = getCurrentPageGroup();
    const start = currentGroup * 5 + 1;
    const end = Math.min(start + 4, pageable.totalPages);
    return { start, end };
  };

  return (
    <Wrapper>
      <Title>문의 내역</Title>

      <TabContainer>
        <Tab
          isActive={activeTab === 'PENDING'}
          onClick={() => {
            setActiveTab('PENDING');
            setPageable(prev => ({
              ...prev,
              pageNumber: 1
            }));
          }}
        >
          답변 대기
        </Tab>
        <Tab
          isActive={activeTab === 'COMPLETE'}
          onClick={() => {
            setActiveTab('COMPLETE');
            setPageable(prev => ({
              ...prev,
              pageNumber: 1
            }));
          }}
        >
          답변 완료
        </Tab>
      </TabContainer>

      <NoticeList>
        {notices.map(notice => (
          <NoticeItem key={notice.id} onClick={() => navigate(`/saas/questions/${notice.id}`)}>
            <NoticeContent
            key={notice.id}
            >
              <Text>{notice.title}</Text>
              <Company>{notice.companyName}</Company>

            </NoticeContent>
            <Date>{dateFormat(notice.createdAt)}</Date>
          </NoticeItem>
        ))}
      </NoticeList>

      {/* 페이지네이션 추가 */}
      <PaginationContainer>
        {/* 첫 페이지로 이동 버튼 */}
        <PageButton
          onClick={() => handlePageChange(1)}
          disabled={pageable.first}
        >
          {'<<'}
        </PageButton>

        {/* 이전 페이지 그룹으로 이동 */}
        <PageButton
          onClick={() => handlePageChange(getPageRange().start - 5)}
          disabled={getPageRange().start <= 1}
        >
          {'<'}
        </PageButton>

        {/* 페이지 번호들 */}
        {Array.from(
          { length: getPageRange().end - getPageRange().start + 1 },
          (_, i) => getPageRange().start + i
        ).map(number => (
          <PageButton
            key={number}
            isActive={pageable.pageNumber === number}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </PageButton>
        ))}

        {/* 다음 페이지 그룹으로 이동 */}
        <PageButton
          onClick={() => handlePageChange(getPageRange().end + 1)}
          disabled={getPageRange().end >= pageable.totalPages}
        >
          {'>'}
        </PageButton>

        {/* 마지막 페이지로 이동 버튼 */}
        <PageButton
          onClick={() => handlePageChange(pageable.totalPages)}
          disabled={pageable.last}
        >
          {'>>'}
        </PageButton>
      </PaginationContainer>
    </Wrapper>
  );
};

export default Questions;