import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ReviewContainer = styled.div`
  padding: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PanelContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const LeftPanel = styled.div`
  flex: 1;
  max-width: 600px;
  padding: 20px;
  background: white;
`;

const RightPanel = styled.div`
  flex: 1;
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  height: 80px;
`;

const BottomPanel = styled.div`
  width: 100%;
  background: white;
  padding: 20px;
`;

const BusinessNumberSection = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin-top: 28px;
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #2282e9;
  border-radius: 5px;
  font-size: 14px;
`;

const CheckIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #2282e9;
`;

const InfoCard = styled.div`
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  background: white;
`;

const InfoRow = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

const InfoLabel = styled.div`
  width: 120px;
  color: #666;
  font-size: 14px;
`;

const InfoValue = styled.div`
  flex: 1;
  color: #333;
  font-size: 14px;
  font-weight: 700;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
`;

type CompanyType = 'business' | 'personal' | null;

const SelectButton = styled.button<{ isSelected?: boolean }>`
  padding: 8px 20px;
  border: 1px solid ${props => props.isSelected ? '#2282e9' : '#ddd'};
  border-radius: 5px;
  background: white;
  color: ${props => props.isSelected ? '#2282e9' : '#333'};
  cursor: pointer;
  width: 200px;
  font-size: 14px;

  &:hover {
    background: #f8f9fa;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  &::placeholder {
    color: #999;
  }
`;

const FileUploadButton = styled.button`
  width: 100%;
  padding: 15px;
  border: 1px dashed #2282e9;
  border-radius: 5px;
  background: white;
  color: #2282e9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 14px;

  &:hover {
    background: #f8f9fa;
  }
`;

const FileUploadHint = styled.p`
  font-size: 12px;
  color: #999;
  margin-top: 5px;
`;

const DataWrapper = styled.div`
  display: flex;
  gap: 10px;
`;


const ReviewButton = styled.button`
  width: 15%;
  padding: 15px;
  background: #2282e9;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background: #1a75d2;
  }
`;

interface CompanyReviewProps {
    onClose: () => void;
    businessNumber: string;
    companyType?: CompanyType;
}

const CompanyReview: React.FC<CompanyReviewProps> = ({ onClose, businessNumber, companyType = 'business' }) => {
    return (
        <ReviewContainer>
            <PanelContainer>
                <LeftPanel>
                    <Title>게임사 유형 선택</Title>
                    <SubTitle>게임사업자 또는 법인사업자로 사업자 번호가 있다면 '사업자 만들기'를 선택해주세요.</SubTitle>
                    <SubTitle>사업자가 아닌 개인도 테스트 설계가 가능합니다. 본인에 해당하는 게임사 유형을 선택하세요.</SubTitle>

                    <ButtonGroup>
                        <SelectButton
                            isSelected={companyType === 'business'}
                        >
                            사업자
                        </SelectButton>
                        <SelectButton
                            isSelected={companyType === 'personal'}
                        >
                            개인
                        </SelectButton>
                    </ButtonGroup>

                    <Title style={{ marginTop: '20px' }}>게임사 정보 입력</Title>
                    <SubTitle>사업자 등록 번호만 입력하면 편하게 기본 정보가 등록됩니다.</SubTitle>

                    <BusinessNumberSection>
                        <Label>사업자 등록 번호</Label>
                        <InputWrapper>
                            <Input value={businessNumber} readOnly style={{ width: '100%', boxSizing: 'border-box' }} />
                            <CheckIcon>
                                <FontAwesomeIcon icon={faCheck} />
                            </CheckIcon>
                        </InputWrapper>
                    </BusinessNumberSection>

                    <InfoCard>
                        <InfoRow>
                            <InfoLabel>기업명</InfoLabel>
                            <InfoValue>(주)플리더스</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>사업자등록번호</InfoLabel>
                            <InfoValue>(주)플리더스</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>대표자</InfoLabel>
                            <InfoValue>임찬영</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>설립일</InfoLabel>
                            <InfoValue>20220622</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>소재지</InfoLabel>
                            <InfoValue>경기 고양시 덕양구 항동</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>기업규모</InfoLabel>
                            <InfoValue>소기업</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>기업형태</InfoLabel>
                            <InfoValue>주식회사</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>임직원수</InfoLabel>
                            <InfoValue>6</InfoValue>
                        </InfoRow>
                        <InfoRow style={{ borderBottom: 'none' }}>
                            <InfoLabel>업종</InfoLabel>
                            <InfoValue>미디어/정보통신</InfoValue>
                        </InfoRow>
                    </InfoCard>

                    <Label>게임사 이름</Label>
                    <DataWrapper>
                        <FormInput placeholder="ex) moomogames, 무모게임즈" />
                    </DataWrapper>

                    <Label>제작 중인 게임</Label>
                    <FileUploadButton>+ 파일 올리기</FileUploadButton>
                    <FileUploadHint>10MB 이내PDF,JPEG,PNG 파일을 등록해주세요.</FileUploadHint>

                </LeftPanel>

                <RightPanel>
                    <ReviewButton>
                        심사하기
                    </ReviewButton>
                </RightPanel>
            </PanelContainer>

            <BottomPanel>
                
            <Title>관리자 정보 입력</Title>
                    <SubTitle>위더스 베이커 관리자를 설정해 테스트 설계 및 문의 소통을 원활하게 도와줍니다.</SubTitle>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                        <div>
                            <Label>관리자 이름</Label>
                            <DataWrapper>
                                <FormInput placeholder="ex) 김OO" />
                            </DataWrapper>

                            <Label>역할</Label>
                            <DataWrapper>
                                <FormInput placeholder="ex) PM, 디자이너, 매니저" />
                            </DataWrapper>

                            <Label>휴대폰 번호</Label>
                            <DataWrapper>
                                <FormInput placeholder="ex) 010-0000-0000" />                            
                            </DataWrapper>
                        </div>

                        <div>
                            <Label>직급</Label>
                            <DataWrapper>
                                <FormInput placeholder="ex) 사원, 팀장, 실장, 대표" />
                            </DataWrapper>

                            <Label>연락 가능 이메일</Label>
                            <DataWrapper>
                                <FormInput placeholder="ex) plithus@naver.com" />
                            </DataWrapper>

                            <Label>사업자등록증</Label>
                            <FileUploadButton>+ 파일 올리기</FileUploadButton>
                            <FileUploadHint>10MB 이내PDF,JPEG,PNG 파일을 등록해주세요.</FileUploadHint>
                        </div>
                    </div>
            </BottomPanel>
        </ReviewContainer>
    );
};

export default CompanyReview;