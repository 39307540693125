import React, { useState } from 'react';
import styled from 'styled-components';

interface SearchBarProps {
    placeholder?: string;
    onSearch?: (searchTerm: string) => void;
    className?: string;
}

const SearchContainer = styled.div`
 justify-content: center; // 가로 중앙 정렬
  display: flex;

  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 999px;
  padding: 8px 16px;
  box-sizing: border-box;
   margin: 0 auto;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: #333;
  font-size: 14px;
  &::placeholder {
    color: #999;
  }
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
`;

const SearchIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
);

const SearchBar: React.FC<SearchBarProps> = ({
    placeholder = "개인사 이름을 검색하세요.",
    onSearch,
    className = "",
}) => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = () => {
        if (onSearch) {
            onSearch(searchTerm);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <SearchContainer className={className}>
            <SearchInput
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            <SearchButton
                onClick={handleSearch}
                aria-label="Search"
            >
                <SearchIcon />
            </SearchButton>
        </SearchContainer>
    );
};

export default SearchBar;
