import React, { useState } from 'react';
import styled from 'styled-components';

// 드롭다운 컨테이너 스타일
const DropdownContainer = styled.div`
  position: relative;
  justify-content: space-between;
  width: 100px; // 너비 조정
  user-select: none;
  margin: 0 auto; // 가운데 정렬을 위해 추가
`;

// 드롭다운 헤더 스타일 (클릭하는 부분)
const DropdownHeader = styled.div<{ labelColor?: string }>`
  padding: 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.labelColor || '#333'};
  font-size: 14px;
`;

// 드롭다운 아이콘
const DropdownIcon = styled.span`
  margin-left: 10px;
  color: #888;
`;

// 드롭다운 목록 스타일
const DropdownListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

// 드롭다운 목록 항목 스타일
const DropdownListItem = styled.div<{ labelColor?: string }>`
  padding: 10px;
  color: ${props => props.labelColor || '#333'};
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #f5f5f5;
  }
`;

// 드롭다운 컴포넌트
interface CustomDropdownProps {
    label: string;
    options: string[];
    onChange?: (selectedOption: string) => void;
    labelColor?: string; // 글씨 색상을 위한 prop 추가
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
    label,
    options,
    onChange,
    labelColor
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (value: string) => {
        setSelectedOption(value);
        setIsOpen(false);

        if (onChange) {
            onChange(value);
        }
    };

    const displayText = selectedOption || label;

    return (
        <DropdownContainer>
            <DropdownHeader onClick={toggling} labelColor={labelColor}>
                {displayText}
                <DropdownIcon>▼</DropdownIcon>
            </DropdownHeader>
            {isOpen && (
                <DropdownListContainer>
                    {options.map(option => (
                        <DropdownListItem
                            onClick={() => onOptionClicked(option)}
                            key={option}
                            labelColor={labelColor}
                        >
                            {option}
                        </DropdownListItem>
                    ))}
                </DropdownListContainer>
            )}
        </DropdownContainer>
    );
};

export default CustomDropdown;
