import instance from "../../../../../API/MainAPI";

export function GetQuestionsListApi(page: number, pageSize: number, status: string) {
    return instance.get("/v1/admins/labs/inquiries/managements", {
        params: {
            status: status,
            pageSize: pageSize,
            pageNumber: page - 1,
        },
    });
}

export function GetQuestionDetailApi(id: number) {
    return instance.get(`/v1/admins/labs/inquiries/managements/${id}`);
}

export function PostQuestionReply(id:string, reply: string) {
    return instance.post(`/v1/admins/labs/inquiries/managements/${id}`, {
        response: reply
    });
}