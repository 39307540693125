import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { GetQuestionDetailApi, PostQuestionReply } from '../Data/QuestionsApi';
import { toast } from 'react-toastify'; // 에러 알림을 위한 라이브러리
import { dateFormat } from "../../../../common/function/date";
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
`;
const InnerContainer = styled.div`
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  padding: 16px;
  margin-bottom: 12px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const Subtitle = styled.div`
color:rgb(0, 0, 0);

  font-weight: bold;
  margin-bottom: 14px;
`;

const CompanyName = styled.div`
color: #007bff;
  margin-bottom: 14px;
`;

const DateText = styled.p`
  color: #888;
  font-size: 14px;
`;

const Content = styled.p`
  font-size: 14px;
  color: #333;
  line-height: 1.6;
`;
const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
const Button = styled.button`
  background: #f2f2f2;
  border: 1px solid #ccc;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-top: 12px;
`;

const ReplyBox = styled.textarea`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border: none;
  padding: 12px;
  font-size: 14px;
  border-radius: 4px;
  resize: vertical;
  min-height: 150px;
  box-sizing: border-box;  // padding이 width에 포함되도록 설정

`;
const Wrapper = styled.div`
  flex: 4;
`;
const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 12px 0;
`;
// 이미지를 담을 스타일 컴포넌트 추가
const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);  // 2개의 열
  gap: 16px;  // 이미지 간 간격
  margin: 20px 0;
`;

const ImageContainer = styled.div`
  margin: 20px 0;
  // 필요한 경우 크기 조절
    width: 100%
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
`;

interface QuestionDetail {
    id: number;
    status: string;
    title: string;
    companyName: string;
    content: string;
    createdAt: string | Date;
    response: string;
    respondedAt: string,
    attachmentUrls: string[];

}



const InquiryPage: React.FC = () => {
    const navigate = useNavigate();
    const [questionData, setQuestionData] = useState<QuestionDetail | null>(null);
    const [reply, setReply] = useState("");
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [detail, setDetail] = useState();

    // const id = parseInt(idAsString);
    console.log("id : " + id);

    const setQuestionDetail = (data: QuestionDetail) => {
        if (!data) return;

        setQuestionData({
            ...data,
            createdAt: new Date(data.createdAt)
        });
    }

    const fetchNoticesData = async (id: number) => {
        try {
            const response = await GetQuestionDetailApi(id);
            if (response) {
                setQuestionDetail(response.data);
            }
        } catch (error) {
            setError('데이터를 불러오는데 실패했습니다.');
            toast.error('데이터를 불러오는데 실패했습니다.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchNoticesData(parseInt(id, 10));
        }
    }, [id]);


    if (!questionData) return <div>데이터를 찾을 수 없습니다.</div>;

    const handleSubmitReply = async () => {
        console.log("제출 버튼: " + reply);
        if(!id) return;

        await PostQuestionReply(id, reply);
        navigate(0);
    }

    return (
        <Wrapper>
            <Title>문의 내역</Title>
            <Container>
                <InnerContainer>
                    <Card>
                        <Content>
                            <Subtitle>문의 제목</Subtitle>
                            <Title>{questionData.title}</Title>
                            <InfoRow>
                                <CompanyName>{questionData.companyName}</CompanyName>
                                <DateText>{dateFormat(new Date(questionData.createdAt))}</DateText>
                            </InfoRow>
                            <Divider />
                        </Content>

                        <Content>
                            <Subtitle>문의 내용</Subtitle>
                            {questionData.content}
                        </Content>
                        {/* 첨부파일이 있는 경우 */}
                        <Content>
                            <Subtitle> 첨부 파일 개수: {questionData.attachmentUrls.length}개</Subtitle>
                        </Content>
                        <ImageGrid>
                            {questionData.attachmentUrls && questionData.attachmentUrls.map((url, index) => (
                                <ImageContainer key={index}>
                                    <StyledImage src={url} alt={`첨부 이미지 ${index + 1}`} />
                                </ImageContainer>
                            ))}
                        </ImageGrid>
                        <Button>⬇ 다운로드</Button> {/* TODO: 첨부파일 다운로드 해야 함. */}
                    </Card>
                    <Card>
                        <ReplyBox
                            value={reply}
                            onChange={(e) => setReply(e.target.value)}
                            placeholder="↳ 답변을 입력하세요... (재제출하면 답변이 수정됩니다.)"
                        />
                    </Card>
                </InnerContainer>
                <Button onClick={handleSubmitReply}>답변 제출</Button>
                <Divider />
                <Content>
                    <Subtitle>등록된 답변</Subtitle>
                    
                        {questionData.response === "" ? '등록 답변 없음' : (
                            <>
                                {questionData.response}
                                <InfoRow>
                                <DateText>{dateFormat(new Date(questionData.respondedAt))}</DateText>
                                </InfoRow>
                            </>
                        )}
                    
                </Content>
            </Container>
        </Wrapper>
    );
};

export default InquiryPage;
