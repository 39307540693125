import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { EditDayMissionGameDto, EditDayMissionGameFileDto } from "../types";

export interface DayMissionGameEditForm
  extends EditDayMissionGameDto,
  EditDayMissionGameFileDto { }

export type HandleSubmitFunc = (
  data: DayMissionGameEditForm
) => Promise<unknown> | unknown;

const useDayMissionGameEditForm = (
  handleSubmitProp: HandleSubmitFunc = () => { }
) => {
  const {
    control,
    register,
    handleSubmit: onSubmit,
    ...props
  } = useForm<DayMissionGameEditForm>({
    defaultValues: {
      genres: [],
    },
  });

  const _handleSubmit: SubmitHandler<DayMissionGameEditForm> = async (data) => {
    await handleSubmitProp(data);
  };
  const _handleError: SubmitErrorHandler<DayMissionGameEditForm> = (data) => {
    console.log(data);
    window.alert("채우지 않은 필수 항목이 있습니다.");
  };
  const handleSubmit = onSubmit(_handleSubmit, _handleError);
  return {
    handleSubmit,
    inputs: {
      title: register("title"),
      subtitle: register("subtitle"),
      company: register("company", { required: true }),
      thumbnail: register("thumbnail"),
      appStoreLink: register("appStoreLink"),
      playStoreLink: register("playStoreLink"),
      youtubeLink: register("youtubeLink"),
      startedAt: register("startedAt", { required: true }),
      expiredAt: register("expiredAt", { required: true }),
      genres: register("genres"),
      description: register("description"),
      visible: register("visible"),
      gameImages: register("gameImages"),
    },
    control,
    register,
    ...props,
  };
};

export default useDayMissionGameEditForm;
