interface DateFormatOptions {
  year: "numeric";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
}

export default function date(date: Date) {
  if (date) {
    const options: DateFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("ko", options);
  } else return "-";
}

export function dateFormat(date: Date) {
  if (date) {
    const year = new Date(date).getFullYear();
    const month = String(new Date(date).getMonth() + 1).padStart(2, '0');
    const day = String(new Date(date).getDate()).padStart(2, '0');
    
    return `${year}.${month}.${day}`;
  } else return "-";
}